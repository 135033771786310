import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import useScript from '../components/useScript';
import useStyle from '../components/useStyle';

const AppMeteo = () => {

  useScript('/Variables.js', false)
  useStyle('/StylVersion1.css')
  useScript('https://d3js.org/d3.v3.min.js', false)
  useScript('/Previsions/selectDate.js', false)
  useScript('/TabJsonStationNC.js', false)
  useScript('/PointsLocalites.js', false)
  useScript('/AppelAjaxCR.js', false)

  useScript('/main.js', false)
  useScript('/CarteGeomet.js', false)
  useScript('/ZoomClic.js', false)
  useScript('/CarteGeometZoom.js', false)
  useScript('/BtnChoixInfoGeomet.js', false)
  useScript('/EtiqDateHeure1.js', false)
  useScript('/EtiqDateHeure2.js', false)
  useScript('/CarteChoix.js', false)
  useScript('/InfoCarteSup.js', false)
  useScript('/Previsions/FramePopPrecip_v3.js', false)
  useScript('/Previsions/FrameNeb_v2.js', false)
  useScript('/Previsions/FrameTaTdU_v2.js', false)
  useScript('/Previsions/FrameVents_v3.js', false)
  useScript('/InfoCarte511.js', false)
  useScript('/Carte511.js', false)
  useScript('/Legende511.js', false)
  useScript('/Previsions/Vignettes.js', false)

  return (
    <Layout>
      <Seo title="Application météo" />
      <div>
        <div>
          <h1>Application météo</h1>
        </div>

        <header className="application-meteo">
          <div className="container h-100">
            <div className="row text-white text-center mb-5">

              <div align="center" style={{position: 'absolute', top: 110, left: '50%', marginLeft: -300}}>
                <svg width="604" height="900">
                  <rect width="600" height="860" x="2" y="2" rx="10" className="fondFill" />
                </svg>
              </div>

              <div align="center" style={{position: 'absolute', top: 170, left: '50%', marginLeft: -281, display: 'block'}}>
                <div align="center" id="CarteGeomet" className="CarteGeomet">
                </div>
              </div>

              <div align="center" style={{position: 'absolute', top: 170, left: '50%', marginLeft: -281, display: 'block'}}>
                <div align="center" id="ZoomClic" className="ZoomClic">
                </div>
              </div>

              <div align="center" style={{position: 'absolute', top: 570, left: '50%', marginLeft: -281, display: 'block'}}>
                <div align="center" id="CarteGeometZoom" className="CarteGeometZoom">
                </div>
              </div>

              <div align="center" style={{position: 'absolute', top: 122, left: '50%', marginLeft: -245}}>
                <div align="center" id="BtnChoixInfoGeomet" className="BtnChoixInfoGeomet">
                </div>
              </div>

              <div align="center" style={{position: 'absolute', top: 530, left: '50%', marginLeft: -252}}>
                <input type="range" name="SlideGeomet1" min="0" max="70" step="1" value="70" id="SlideGeomet1" className="SlideGeomet"
                oninput="javascript: valueSlide=this.value; slideImgGeomet(valueSlide); FnEtiqDateHeure1(); FnEtiqDateHeure2();" />
              </div>

              <div align="center" style={{position: 'absolute', top: 490, left: '50%', marginLeft: -280}}>
                <div align="center" id="EtiqDateHeure1" className="EtiqDateHeure1">
                </div>
              </div>

              <div align="center" style={{position: 'absolute', top: 930, left: '50%', marginLeft: -252}}>
                <input type="range" name="SlideGeomet2" min="0" max="70" step="1" value="70" id="SlideGeomet2" className="SlideGeomet"
                oninput="javascript: valueSlide=this.value; slideImgGeomet(valueSlide); FnEtiqDateHeure1(); FnEtiqDateHeure2();" />
              </div>

              <div align="center" style={{position: 'absolute', top: 890, left: '50%', marginLeft: -280}}>
                <div align="center" id="EtiqDateHeure2" className="EtiqDateHeure2">
                </div>
              </div>

              <div align="center" style={{position: 'absolute', top: 575, left: '50%', marginLeft: -40}}>
                <img src="/Icones/Haut2.svg" width="80" id="btnCarte" className="btnCarte"
                  onclick="javascript: translatedYGeomet = translatedYGeomet + 40; FnCarteGeometZoom(translatedXGeomet,translatedYGeomet);" />
              </div>

              <div align="center" style={{position: 'absolute', top: 690, left: '50%', marginLeft: -275}}>
                <img src="/Icones/Gauche2.svg" height="80" id="btnCarte" className="btnCarte"
                  onclick="javascript: translatedXGeomet = translatedXGeomet + 40; FnCarteGeometZoom(translatedXGeomet,translatedYGeomet);" />
              </div>

              <div align="center" style={{position: 'absolute', top: 690, left: '50%', marginLeft: 230}}>
                <img src="/Icones/Droite2.svg" height="80" id="btnCarte" className="btnCarte"
                  onclick="javascript: translatedXGeomet = translatedXGeomet - 40; FnCarteGeometZoom(translatedXGeomet,translatedYGeomet);" />
              </div>

              <div align="center" style={{position: 'absolute', top: 850, left: '50%', marginLeft: -40}}>
                <img src="/Icones/Bas2.svg" width="80" id="btnCarte" className="btnCarte"
                  onclick="javascript: translatedYGeomet = translatedYGeomet - 40; FnCarteGeometZoom(translatedXGeomet,translatedYGeomet);" />
              </div>

              <div align="center" style={{position: 'absolute', top: 1060, left: '50%', marginLeft: -300}}>
                <svg width="605" height="545">
                  <rect width="600" height="540" x="2" y="2" rx="10" class="fondFill"/>
                </svg>
              </div>

              <div align="center" id="CarteChoix" className="CarteChoix" style={{position: 'absolute', top: 1255, left: '50%', marginLeft: -290}}>
                <div align="center">
                  <img src="/Icones/Haut.svg" width="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedY = translatedY + 40, FnCarteChoix(translatedX,translatedY,zoomedMap,codePrevision);" />
                </div>

                <div align="center" style={{position: 'absolute', top: 120, left: '50%', marginLeft: -295}}>
                  <img src="/Icones/Gauche.svg" height="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedX = translatedX + 40, FnCarteChoix(translatedX,translatedY,zoomedMap,codePrevision);" />
                </div>

                <div align="center" style={{position: 'absolute', top: 120, left: '50%', marginLeft: 250}}>
                  <img src="/Icones/Droite.svg" height="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedX = translatedX - 40, FnCarteChoix(translatedX,translatedY,zoomedMap,codePrevision);" />
                </div>

                <div align="center" style={{position: 'absolute', top: 280, left: '50%', marginLeft: -30}}>
                  <img src="/Icones/Bas.svg" width="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedY = translatedY - 40, FnCarteChoix(translatedX,translatedY,zoomedMap,codePrevision);" />
                </div>

                <div align="center" style={{position: 'absolute', top: 235, left: '50%', marginLeft: 250}}>
                  <img src="/Icones/Plus.svg" width="45" height="40" id="btnCarte" className="btnCarte"
                    onclick="javascript: zoomIn();" />
                </div>

                <div align="center" style={{position: 'absolute', top: 285, left: '50%', marginLeft: 250}}>
                  <img src="/Icones/Moins.svg" width="45" height="40" id="btnCarte" className="btnCarte"
                    onclick="javascript: zoomOut();" />
                </div>

              </div>

              <div align="center" id="InfoCarteSup" className="InfoCarteSup" style={{position: 'absolute', top: 1070, left: '50%', marginLeft: -300}}>
              </div>


              <div align="center" id="Vignettes" className="Vignettes" style={{position: 'absolute', top: 1095, left: '50%', marginLeft: -290}}>
              </div>

              <div align="center" style={{position: 'absolute', top: 1130, left: '50%', marginLeft: -260}}>
                <img id="iconeJour1" src="/Icones/PartCouvertNuit.svg" width="60px" onclick="clicVignette(1);" style={{cursor: 'pointer'}} />
              </div>

              <div align="center" style={{position: 'absolute', top: 1130, left: '50%', marginLeft: -145}}>
                <img id="iconeJour2" src="/Icones/PartCouvertNuit.svg" width="60px" onclick="clicVignette(2);" style={{cursor: 'pointer'}} />
              </div>

              <div align="center" style={{position: 'absolute', top: 1130, left: '50%', marginLeft: -30}}>
                <img id="iconeJour3" src="/Icones/PartCouvertNuit.svg" width="60px" onclick="clicVignette(3);" style={{cursor: 'pointer'}} />
              </div>

              <div align="center" style={{position: 'absolute', top: 1130, left: '50%', marginLeft: 85}}>
                <img id="iconeJour4" src="/Icones/PartCouvertNuit.svg" width="60px" onclick="clicVignette(4);" style={{cursor: 'pointer'}} />
              </div>

              <div align="center" style={{position: 'absolute', top: 1130, left: '50%', marginLeft: 200}}>
                <img id="iconeJour5" src="/Icones/PartCouvertNuit.svg" width="60px" onclick="clicVignette(5);" style={{cursor: 'pointer'}} />
              </div>

              <div align="center" style={{position: 'absolute', top: 1130, left: '50%', marginLeft: -30}}>
                <img id="iconeJour0" src="/Icones/Couvert.svg" width="60px" style = "display:none" onclick="clicVignette(0);" style={{cursor: 'pointer'}} />
              </div>


              <div align="center" id="selectDate" className="selectDate" style={{position: 'absolute', top: 1693, left: '50%', marginLeft: -300}}>
              </div>

              <img src="/Icones/Avant.svg" width="45" height="40" id="btnPrevAvant" className="btnPrevAvant" style={{position: 'absolute', top: 1700, left: '50%', marginLeft: -295 }}
                onclick="javascript: recul_date();" />

              <img src="/Icones/Apres.svg" width="45" height="40" id="btnPrevApres" className="btnPrevApres" style={{position: 'absolute', top: 1700, left: '50%', marginLeft: 254 }}
                onclick="javascript: avance_date();" />


              <div align="center" style={{position: 'absolute', top: 1755, left: '50%', marginLeft: -300}}>
                <svg width="605" height="235">
                  <rect width="596" height="225" x="2" y="2" rx="10" className="fondFill" />
                </svg>
              </div>

              <div align="center" id="PopPrecip" className="PopPrecip" style={{position: 'absolute', top: 1735, left: '50%', marginLeft: -300}}>
              </div>

              <div align="center" id="Neb" className="Neb" style={{position: 'absolute', top: 1892, left: '50%', marginLeft: -302}}>
              </div>

              <div align="center" id="TaTdU" className="TaTdU" style={{position: 'absolute', top: 1995, left: '50%', marginLeft: -300}}>
              </div>

              <div align="center" id="Vents" className="Vents" style={{position: 'absolute', top: 2265, left: '50%', marginLeft: -300}}>
              </div>

              <div align="center" style={{position: 'absolute', top: 2580, left: '50%', marginLeft: -300}}>
                <svg width="605" height="505">
                  <rect width="600" height="500" x="2" y="2" rx="10" className="fondFill" />
                </svg>
              </div>

              <div align="center" id="Carte511" className="Carte511" style={{position: 'absolute', top: 2650, left: '50%', marginLeft: -290}}>
                <div align="center" style={{position: 'absolute', top: 0, left: '50%', marginLeft: -30}}>
                  <img src="/Icones/Haut.svg" width="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedY511 = translatedY511 + 40; FnCarte511();" />
                </div>

                <div align="center" style={{position: 'absolute', top: 120, left: '50%', marginLeft: -295}}>
                  <img src="/Icones/Gauche.svg" height="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedX511 = translatedX511 + 40; FnCarte511();" />
                </div>

                <div align="center" style={{position: 'absolute', top: 120, left: '50%', marginLeft: 250}}>
                  <img src="/Icones/Droite.svg" height="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedX511 = translatedX511 - 40; FnCarte511();" />
                </div>

                <div align="center" style={{position: 'absolute', top: 280, left: '50%', marginLeft: -30}}>
                  <img src="/Icones/Bas.svg" width="80" id="btnCarte" className="btnCarte"
                    onclick="javascript: translatedY511 = translatedY511 - 40; FnCarte511();" />
                </div>


                <div align="center" style={{position: 'absolute', top: 235, left: '50%', marginLeft: 250}}>
                  <img src="/Icones/Plus.svg" width="45" height="40" id="btnCarte" className="btnCarte"
                    onclick="javascript: zoomIn511();" />
                </div>

                <div align="center" style={{position: 'absolute', top: 285, left: '50%', marginLeft: 250}}>
                  <img src="/Icones/Moins.svg" width="45" height="40" id="btnCarte" className="btnCarte"
                    onclick="javascript: zoomOut511();" />
                </div>

              </div>

              <div align="center" style={{position: 'absolute', top: 2600, left: '50%', marginLeft: -300}}>
                <svg width="600" height="50">
                  <text x="300" y="20" id="txtTitreCR" className="txtTitreCR">Conditions routières</text>
                </svg>
              </div>

              <div align="center" id="InfoCarte511" className="InfoCarte511" style={{position: 'absolute', top: 2620, left: '50%', marginLeft: -300}}>
              </div>

              <div align="center" id="Legende511" className="Legende511" style={{position: 'absolute', top: 2990, left: '50%', marginLeft: -295}}>
              </div>

              <div align="center" style={{position: 'absolute', top: 2605, left: '50%', marginLeft: 210}}>
                <input type="range" name="NomSlideCR" min="0" max="2" step="1" value="0" id="SlideCR" className="SlideCR"
                oninput="javascript: valueSlideCR=this.value; slideCR(valueSlideCR);" />
              </div>

            </div>
          </div>
        </header>

        <section className="page-section" id="expertise">
          <div className="bg-top"></div>
          <div className="container">
            test
          </div>
          <div className="bg-bottom"></div>
        </section>

      </div>
    </Layout>
  )
}

export default AppMeteo;
